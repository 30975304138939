import {
  invalidMobileNumber,
  invalidOneTimeCode,
  mobileNumberIsRequired,
  oneTimeCodeIsRequired,
  invalidDateOfBirthError,
} from "./ErrorMessages";
import { industryOptions } from "../constants";

// export const asicRegex = /[^a-zA-Z0-9 !":#;$=%?&@'()*,.\-\/]/; ASIC reference
export const businessNameRegex = /[^a-zA-Z0-9 ]/; // Coles is more restrictive
export const numericalOnlyRegex = /^[0-9]*$/g;
export const numericalOnlyWithSpaceAndDashRegex = /[^0-9 -]/g;
export const numericalKeyOnlyWithSpaceAndDashRegex = /[0-9 -]/;
export const spacesRegex = /\s/g;
export const dashRegex = /-/g;

export const maxOneTimeCodeLength = 6;

export function sanitiseMobileNumber(mobileNo: string): string {
  return mobileNo.replace(spacesRegex, "").replace(dashRegex, "");
}

export function validateBusinessName(businessName: string): boolean {
  if (
    businessName &&
    businessName.length &&
    businessName.length >= 3 &&
    !businessNameRegex.test(businessName)
  ) {
    return true;
  }
  return false;
}

export function validateAbn(value: string): boolean {
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

  if (!value || !value.length || value.replace(spacesRegex, "").length !== 11) {
    return false;
  }

  const abn = value.replace(/[^\d]/g, "");
  let sum = 0;
  for (var i = 0; i < weights.length; i++) {
    sum += weights[i] * (parseInt(abn[i]) - (i ? 0 : 1));
  }

  return sum % 89 === 0;
}

export function validateIndustry(industry: string): boolean {
  return industryOptions.includes(industry);
}

export function validateOneTimeCode(oneTimeCode: string): boolean | string {
  if (!oneTimeCode) {
    return oneTimeCodeIsRequired;
  }
  oneTimeCode = oneTimeCode.trim();

  const isValid =
    oneTimeCode.match(numericalOnlyRegex) &&
    oneTimeCode.length === maxOneTimeCodeLength;

  return !!isValid || invalidOneTimeCode;
}

export const maxMobileNumberLength = 10;

export function validateMobileNumberIfRequired(
  mobile: string,
  isRequired: boolean
): boolean | string {
  if (!mobile) {
    return isRequired ? mobileNumberIsRequired : true;
  }
  const mobileWithoutSpacesAndLength = sanitiseMobileNumber(mobile);
  const hasNumericCharactersOnly = !mobileWithoutSpacesAndLength.match(
    numericalOnlyWithSpaceAndDashRegex
  );
  const hasTenDigits =
    mobileWithoutSpacesAndLength.length === maxMobileNumberLength;
  const isMobile = mobileWithoutSpacesAndLength.startsWith("04");
  const isValid = hasNumericCharactersOnly && hasTenDigits && isMobile;

  return isValid || invalidMobileNumber;
}

export function validateMobileNumber(mobile: string): boolean | string {
  return validateMobileNumberIfRequired(mobile, true);
}

export interface DateOfBirthInput {
  newDate: Date;
  day: number;
  month: number;
  year: number;
}

export function validateDateOfBirth(value: DateOfBirthInput): boolean | string {
  const currentDateWithTime = new Date();
  const today = new Date(
    currentDateWithTime.getFullYear(),
    currentDateWithTime.getMonth(),
    currentDateWithTime.getDate()
  );
  const dateOfBirth = value?.newDate;
  if (dateOfBirth) {
    return dateOfBirth.valueOf() < today.valueOf() || invalidDateOfBirthError;
  }
  // day, month, year are NaN if empty
  const invalidDay = isNaN(value?.day);
  const invalidMonth = isNaN(value?.month);
  const invalidYear = isNaN(value?.year);
  // as field is optional, if all are empty this is valid
  if (invalidDay && invalidMonth && invalidYear) {
    return true;
  }
  // if all are *technically* valid but we have no date, the value must be out of range (i.e. 31st February)
  return invalidDateOfBirthError;
}
